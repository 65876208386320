$msc-invoice-details-container-margin-top:38px;
$msc-invoice-details-container-padding-bottom: 84px;
$msc-invoice-details-header-padding-bottom: 36px;
$msc-invoice-details-invoice-id-padding-bottom: 28px;
$msc-invoice-details-invoice-id-margin-bottom: 31px;
$msc-invoice-details-invoice-info-container-margin-bottom: 47px;
$msc-invoice-details-order-date-padding-right: 10px;
$msc-invoice-details-order-item-count-padding: 0 10px;
$msc-invoice-details-amount-summary-margin-top: 30px;
$msc-invoice-details-amount-due-text-margin-bottom: 8px;
$msc-invoice-details-amount-due-amount-margin-bottom: 6px;
$msc-invoice-details-actions-pay-invoice-margin-top: 30px;
$msc-invoice-details-products-padding-top: 40px;
$msc-invoice-details-product-line-padding-bottom: 44px;
$msc-invoice-details-product-details-padding-left: 5px;
$msc-invoice-details-payment-details-padding-top: 40px;
$msc-invoice-details-payment-details-line-padding-bottom: 16px;
$msc-invoice-details-total-amount-margin-top: 8px;
$msc-invoice-details-total-amount-padding-top: 24px;
$msc-invoice-details-main-info-margin-bottom: 17px;

.msc-invoice-details {
    margin-top: $msc-invoice-details-container-margin-top;
    padding-bottom: $msc-invoice-details-container-padding-bottom;
    border-bottom: 0.5px solid $msv-gray-50;

    &__products-table--col-3 {
        text-transform: uppercase;
    }

    &__header {
        // padding-bottom: $msc-invoice-details-header-padding-bottom;
        // border-bottom: 0.5px solid $msv-gray-50;
        pre {
            // @include font-body-regular-m();
            margin: 0;
        }
        &__invoice-id {
            @include font-heading-h2-l();
            padding-bottom: $msc-invoice-details-invoice-id-padding-bottom;
            border-bottom: 0.5px solid $msv-gray-50;
            margin-bottom: $msc-invoice-details-invoice-id-margin-bottom;
            text-align: right; // TS - 6540
        }

        &__order-info {
            display: flex;
            flex-direction: column;
            align-items: self-end;
            margin-bottom: 30px;

            &--customer, &--sales-order, &--ship-to {
                width: 40%;
                @include font-heading-h5-l();
                pre {
                    margin-left: 78px;
                }
            }
            &--row {
                display: flex;
                @include font-heading-h4-l();
                // &-label {
                // }
                &-data {
                    width: 375px;
                    padding-left: 5px;
                    &-ship {
                        width: 375px;
                        padding-left: 5px;
                        // font-weight: 400;
                        @include font-body-regular-m();
                        font-size: 24px;
                        line-height: 28px;
                        color: $msv-text-color;
                        pre {
                            @include font-body-regular-m();
                            font-size: 24px;
                            line-height: 28px;
                            text-wrap: wrap;
                            color: $msv-text-color;
                        }
                    }
                }
            }
            @media only screen and (max-width: $msv-breakpoint-m) {
                align-items: start;
                margin-bottom: 20px;
                &--row-data {
                    width: inherit;
                    &-ship {
                        font-size: 18px;
                        pre {
                            font-size: 18px;
                        }
                    }
                }
            }
        }


        &__invoice-info-container {
            // margin-bottom: $msc-invoice-details-invoice-info-container-margin-bottom;
            @media only screen and (min-width: $msv-breakpoint-m) {
                display: flex;
                justify-content: space-between;
            }
            
            &__order-info-container {
                @media only screen and (min-width: $msv-breakpoint-m) {
                    margin-top: -50px;
                }
                &__order-info {
                    // display: flex;
                    // flex-direction: column;
                    // align-items: self-end;

                    // &--customer, &--sales-order, &--ship-to {
                    //     width: 33%;
                    // }
                    // &--sales-order {
                    //     width: 33%;
                    // }
                    // &--ship-to {
                    //     width: 33%;
                    //     pre {
                    //         @include font-body-regular-m();
                    //         margin: 0;
                    //     }
                    // }
                    &__main-info {
                        // @include font-body-regular-m();
                        @include font-heading-h5-l();
                        color: $msv-text-color;
                        pre {
                            @include font-heading-h5-l();
                            color: $msv-text-color;
                        }
                        // margin-bottom: $msc-invoice-details-main-info-margin-bottom;
                    }

                    &__details {
                        display: flex;

                        @include font-body-regular-m();

                        &__order-date {
                            padding-right: $msc-invoice-details-order-date-padding-right;
                            border-right: 1px solid $msv-gray-20;
                        }

                        &__order-items-count {
                            padding: $msc-invoice-details-order-item-count-padding;
                        }
                    }
                }
            }

            &__amount-summary {
                padding: 10px;
                width: 375px;
                border: black 1px solid;
                color: white;
                background-color: var(--msv-primary-btn-light-bg-color);
                text-align: left;
                // @media only screen and (min-width: $msv-breakpoint-l) {
                //     text-align: right;
                // }

                @media only screen and (max-width: $msv-breakpoint-l) {
                    margin-top: $msc-invoice-details-amount-summary-margin-top;
                }

                &__amount-due-text {
                    @include font-heading-h5-l();
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 5px;
                }

                // &__amount-due-amount {
                //     @include font-heading-h3-l();
                //     margin-bottom: $msc-invoice-details-amount-due-amount-margin-bottom;
                // }

                // &__amount-due-date {
                //     @include font-body-regular-s();
                //     color: $msv-gray-40;
                // }
            }
        }

        &__actions {
            display: flex;
            &__pay-invoice {
                @include primary-button-light();
                margin-top: 0;
            }
            &__print-button-container {
                align-self: center;
                // margin-left: 40px;
            }
            @media only screen and (max-width: $msv-breakpoint-l) {
                // margin-top: $msc-invoice-details-actions-pay-invoice-margin-top;
                &__pay-invoice {
                    width: 100%;
                    // margin-top: 0;
                }
            }
        }
    }

    &__products {
        padding-top: $msc-invoice-details-products-padding-top;
        border-bottom: 0.5px solid $msv-gray-50;
        // border-bottom: 2px dashed black;
        &-table {
            @include font-body-regular-m();
            thead {
                background-color: var(--msv-primary-btn-light-bg-color);
                color: #fff;
                tr th{
                    border-bottom: none;
                }
                tr:first-child th{
                    font-size: 18px;
                    border-bottom: none;
                    
                }
            }
            tbody tr{
                &:first-child td{
                    border-top: none;
                }
            }
            &--col-4 {
                text-align: center;
            }
    
            &--col-5, &--col-6, &--col-7 {
                text-align: right;
            }

            @media only screen and (max-width: $msv-breakpoint-m) {
                &--col-5 {
                    display: none;
                }
            }
            @media only screen and (max-width: 560px) {
                &--col-1, &--col-6 {
                    display: none;
                }
            }
            @media only screen and (max-width: 440px) {
                &--col-1, &--col-6 {
                    display: none;
                }
            }
        }

        // &__product-line {
        //     display: flex;
        //     justify-content: space-between;
        //     padding-bottom: $msc-invoice-details-product-line-padding-bottom;

        //     &__product-info-container {
        //         display: flex;

        //         &__product-details {
        //             padding-left: $msc-invoice-details-product-details-padding-left;

        //             &__name {
        //                 @include font-body-bold-m();
        //             }

        //             &__small-details {
        //                 @include font-body-regular-s();
        //             }
        //         }

        //         &__name {
        //             font-weight: $msv-font-weight-bold;
        //             font-size: $msv-font-size-l;
        //             line-height: $msv-line-height-l;
        //         }
        //     }

        //     &__total-price {
        //         @include font-heading-h5-l();
        //     }
        // }
    }

    &__payment-details {
        padding-top: 20px;
        &__detach {
            text-align: center;
            font-size: 12px;
            line-height: 14px;
            // temp
            display: none;
        }

        &__line {
            display: flex;
            justify-content: flex-end;
            padding-bottom: 2px;
            padding-right: .75rem;

            &__line {
                padding-right: 0;
            }

            &__label {
                padding-right: 5px;
            }

            @include font-body-regular-m();

            &-total-amount {
                margin-top: $msc-invoice-details-total-amount-margin-top;
                padding-top: $msc-invoice-details-total-amount-padding-top;
                border-top: 0.5px solid $msv-gray-50;

                @include font-heading-h5-l();
            }
        }

        &__summary {
            display: flex;
            @include font-body-regular-s();
            color: $msv-text-color;
            line-height: 28px;
            margin-top: 20px;

            &--bill-to {
                border: 1px solid #575757;
                padding: 10px 5px;
                margin-right: 2.5%;
                width: 15%;
            }
            &--invoice, &--order {
                border: 1px solid #575757;
                padding: 10px 5px;
                margin-right: 2.5%;
                width: 20%;
            }
            &--total-pieces {
                border: 1px solid #575757;
                padding: 10px 5px;
                margin-right: 2.5%;
                width: 10%;
            }
            &--invoice-total {
                @include font-body-bold-m();
                border: 1px solid #575757;
                padding: 10px 5px;
                width: 25%;
                text-align: right;
                font-size: 20px;
                color: $msv-text-color;
            }
            @media only screen and (max-width: $msv-breakpoint-m) {
                flex-wrap: wrap;
                justify-content: space-evenly;

                &--bill-to {
                    width: 37.5%;
                    margin-bottom: 10px;
                }
                &--invoice, &--order {
                    width: 60%;
                    margin-right: 0;
                    margin-bottom: 10px;
                }
                &--total-pieces {
                    width: 37.5%;
                    margin-left: 2.5%;
                    margin-right: 0;
                    margin-bottom: 10px;
                }
                &--invoice-total {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        &__payment-due-details {
            display: flex;
            @include font-body-regular-s();
            color: $msv-text-color;
            margin-top: 20px;

            &__terms {
                display: flex;
                width: 60%;
                border: 1px solid #575757;
                margin-right: 2.5%;

                &--label {
                    border-right: 1px solid #575757;
                    color: white;
                    background-color: var(--msv-primary-btn-light-bg-color);
                    text-orientation: upright;
                    writing-mode: vertical-rl;
                    padding: 5px;
                }

                &--info {
                    padding: 10px;
                }

                @media only screen and (max-width: $msv-breakpoint-m) {
                    width: 40%;
                }
            }

            &__due {
                border: 1px solid #575757;
                @include font-heading-h3-l();
                color: white;
                background-color: var(--msv-primary-btn-light-bg-color);
                text-align: right;
                width: 37.5%;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &--amount {
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    border-bottom: 2px solid white;
                }
                &--invoice {
                    display: flex;
                    justify-content: flex-end;
                    &-info {
                        padding-left: 5px;
                    }
                }
                @media only screen and (max-width: $msv-breakpoint-m) {
                    width: 63.5%;
                    &--invoice {
                        flex-direction: column;
                    }
                }
            }

        }
    }
    &__shipment {
        &-header {
            margin-top: 50px;
            @include font-heading-h3-l();
            margin-bottom: 10px;
        }

        display: flex;
        @include font-body-regular-m();
        border: 1px solid #575757;
        padding: 20px;
        font-size: 20px;
        color: #575757;
        margin-bottom: 20px;
        // max-height: 200px;

        &--details {
            margin-right: 50px;
            width: 450px;
            border-right: solid 1px #575757;
            flex: 1;
            padding-right: 10px;
            
            &__info {
                display: block;
                margin-top: 2px;
            }
        }

        &--line-details {
            display: flex;
            // flex-direction: column;
            flex-wrap: wrap;
            flex: 2;
            height: max-content;
            &-row {
                margin-top: 2px;
                width: 250px;
                margin-right: 20px;
                font-size: 18px;
            }        
        }
        @media only screen and (max-width: 640px) {
            flex-direction: column;
            &--details {
                border-right: none;
                margin-right: 0;
                padding-right: 0;
                border-bottom: 1px solid #575757;
                padding-bottom: 10px;
                margin-bottom: 10px;
                width: 100%;
            }
            &--line-details {
                font-size: 16px;
                &-row {
                    margin-right: 10px;
                    width: 220px;
                }
            }
        }
    }
    @media print {
        .pagebreak {
            clear: both;
            page-break-after: always;
        }
    }
}
