.back-order-results {
    width: 100%;

    &__spinner {
        display: flex;
        justify-content: center;
        align-items: start;
        position: relative;
        top: 0;
        left: 0;
        height: 50vh;
        gap: 10px;
        @include font-body-regular-m;

        &__loading {
            display: flex;
            margin: 0;
            justify-content: center;
            align-items: center;

            &::before {
                @include msv-icon();
                content: $msv-Spinner;
                margin-right: 8px;
                border-radius: 50%;
                border-width: 1.5px;
                border-style: solid;
                border-color: $msv-order-history-loading-icon-border-color;
                animation: spin 1s steps(8) infinite;
                animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
                width: $msv-order-history-heading-loading-icon-dimension;
                height: $msv-order-history-heading-loading-icon-dimension;
                font-family: inherit;
            }
        }

        &__message {
            @include font-body-regular-s();
            margin: 0 !important;
            text-align: left;
        }
    }

    &__no-backorders {
        display: flex;
        justify-content: center;
        height: 10vh;

        &__message {
            @include font-body-regular-m();
            margin: 0 !important;
            text-align: center;
            font-weight: bold;
        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin: 12px;
        font-size: 16px;
        font-weight: 700;
        &-sort {
            display: flex;

            .back-order-results-dropdown {
                display: flex;
                align-items: center;
                &--label {
                    margin-right: 10px;
                    width: 100px;
                }
                select {
                    // font-weight: 500;
                    @include form-dropdown-decoration;
                    font-size: 14px;
                    line-height: 16px;
                    height: 32px;
                    padding: 5px 10px;
                }
            }
        }

        button {
            @include primary-button-light;
            margin-left: 20px;
            font-size: 20px;
            line-height: 20px;
            min-width: 40px;
            padding: 2px 23px 7px 25px;
            // color: var(--msv-font-secondary-color);
            // -moz-osx-font-smoothing: grayscale;
            // -webkit-font-smoothing: antialiased;
            // border-radius: 2px;
            // border: none;
            // //order: 0;
            // height: 40px;
            // background-color: var(--msv-search-form-search-btn-color);
            // order: 1;
            // font-size: 30px;
            // padding-bottom: 5px;
            // width: 35px;
            // &:hover, &:focus {
            //     color: var(--msv-accent-hover-focus-color);
            //     background-color: var(--msv-search-form-search-btn-focus-hover-color);
            // }
        }
    }
    &__table {
        border-collapse: collapse;
        thead {
            tr th {
                border-bottom: none;
            }
            tr:first-child th {
                font-size: 18px;
                border-bottom: none;
            }
            tr:nth-child(2) {
                background-color: #2d3034;
                color: #fff;
            }
        }
        tbody tr {
            &:first-child td {
                border-top: none;
            }
            td {
                padding: 0.6rem;
                text-align: center;
            }
            td:last-child {
                text-align: center;
            }
        }
        // &--items {
        // text-align: right;
        // display: flex;
        // justify-content: space-between;

        // button {
        //     color: var(--msv-font-secondary-color);
        //     -moz-osx-font-smoothing: grayscale;
        //     -webkit-font-smoothing: antialiased;
        //     border-radius: 2px;
        //     border: none;
        //     //order: 0;
        //     height: 40px;
        //     background-color: var(--msv-search-form-search-btn-color);
        //     order: 1;
        //     font-size: 30px;
        //     padding-bottom: 5px;
        //     width: 35px;
        //     &:hover, &:focus {
        //         color: var(--msv-accent-hover-focus-color);
        //         background-color: var(--msv-search-form-search-btn-focus-hover-color);
        //     }
        // }
        // .back-order-results-dropdown {
        //     display: flex;
        //     align-items: center;
        //     font-size: 14px;
        // }
        // }
    }
    &__list {
        h5 {
            text-align: right;
            margin-bottom: 2px;
        }
        &-double-data {
            display: flex;
            justify-content: space-between;
            margin-top: 2px;
        }
    }
}
