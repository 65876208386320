.ari-search-results {
    width: 100%;
    @media screen and (max-width: $msv-breakpoint-m) {
        .list-group {
            padding-left: 0; // reset padding because ul and ol
            margin-bottom: 0;
            width: 100%;

            > .list-group-item {
                border-bottom: 1px solid grey;
                padding: 1rem;
                background: $msv-gray-100;
                color: var(--msv-primary-font-color);
                cursor: pointer;

                > .list-group-item-heading {
                    font-size: 1.25rem;
                    margin-bottom: 12px;
                }

                > .list-group-item-text {
                    margin-bottom: 12px;
                    letter-spacing: -0.04em;
                    font-weight: 350;
                }
            }
        }
    }
    .list-view__table.table th span a {
        display: inline;
    }
}
