.qo-tooltip {
    max-width: 200px;
    padding: 0.25rem 0.75rem 0.25rem 1.5rem;
    color: #dfdfdf;
    font-size: 0.75em;
    text-align: left;
    background-color: #2d3034;
    border: 1px solid #2d3034;
    border-radius: 0.5rem;
    margin-left: 1em;
    li {
        list-style-type: disc;
    }
}
.eve-quick-order {
    padding: 0;

    .readMore-toolTip {
        @include add-icon($msv-information);
        &:before {
            font-weight: bold;
            font-size: 1.15rem;
            margin-right: 3px;
            margin-bottom: -2px;
        }
        color: #003b64;
        border-bottom: 1px dashed;
        font-style: italic;
        font-size: 0.9rem;
    }

    #manufacturer-codes-select {
        width: 90%;
        font-size: large;
        border: #dfdfdf solid 1px;
        border-radius: 4px;
        overflow: hidden;
        height: 36px;
        text-align: left;
    }
    &-order-upload__guide {
        display: flex;
        flex-flow: column;
        margin: 8px 8px 8px 0;
        height: 90%;
        justify-content: space-evenly;

        &-text-wrapper {
            p {
                text-align: center;
            }
            @media (max-width: $msv-breakpoint-m) {
                margin-bottom: 10px;
            }
        }
    }
    .qo-input {
        border: #dfdfdf solid 1px;
        display: inline-flex;
        border-radius: 4px;
        overflow: hidden;
        width: 80%;
        &__input {
            border: 0;
            padding: 0.5em;
            font-size: 1em;
            width: 100%;
        }
        &__prepend {
            background: #f4f4f4;
            padding: 0.5rem;
            border: #dfdfdf solid 0;
            flex-grow: 0;
        }
    }

    .qo-order-upload-wrapper {
        section {
            button {
                @include primary-button-light;
                padding: 7px 30px;
                min-width: 0;
            }
        }
    }

    .qo-button {
        @include primary-button-light;
        padding: 7px 30px;
        min-width: 0;
    }

    .q-container {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 10px;
        padding-right: 10px;
    }

    .q-table {
        li {
            border-radius: 5px;
            padding: 4px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: $msv-breakpoint-m) {
                padding: 4px 5px;
            }
            &:not(:last-child) {
                margin-bottom: 8px;
            }
            .eve-button-row--error {
                width: 100%;
                display: flex;
                place-content: center;
                .eduerrors {
                    width: fit-content;
                }
            }
        }

        .q-table-header {
            background-color: $gardner-blue-1;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            color: $msv-white;
        }

        .q-table-row {
            background-color: #ffffff;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);

            &--error {
                border: 1px solid $gardner-red-1;
                background-color: rgba($gardner-red-1, 0.2);
            }

            .qo-button {
                &__controls {
                    width: 100%;
                    text-align: center;
                    &--clear-form,
                    &--add-rows {
                        margin-left: 30px;
                    }
                    &--add-to-cart,
                    &--clear-form,
                    &--add-rows {
                        min-width: 200px;
                    }
                }

                @media (max-width: $msv-breakpoint-m) {
                    &__controls {
                        flex-direction: column;
                        display: flex;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        &--clear-form,
                        &--add-rows {
                            margin-left: 0;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }

        .q-col-1 {
            flex-basis: 15%;
            @media (max-width: $msv-breakpoint-m) {
                flex-basis: 30%;
            }
        }

        .q-col-2 {
            flex-basis: 15%;
            @media (max-width: $msv-breakpoint-m) {
                flex-basis: 32.5%;
            }
        }

        .q-col-3 {
            flex-basis: 5%;
            @media (max-width: $msv-breakpoint-m) {
                flex-basis: 12.5%;
            }
        }

        .q-col-4 {
            flex-basis: 25%;
            @media (max-width: $msv-breakpoint-m) {
                display: none;
                flex-basis: 0%;
            }
        }
        .q-col-5 {
            text-align: center;
            flex-basis: 10%;
            @media (max-width: $msv-breakpoint-m) {
                flex-basis: 0%;
                display: none;
            }
        }
        .q-col-6 {
            text-align: center;
            flex-basis: 10%;
            @media (max-width: $msv-breakpoint-m) {
                flex-basis: 0%;
                display: none;
            }

            &__loading-div {
                display: flex;
                justify-content: center;
                align-items: start;
                @include font-body-regular-m;

                &__loading {
                    display: flex;
                    margin: 0;
                    justify-content: center;
                    align-items: center;

                    &::before {
                        @include msv-icon();
                        content: $msv-Spinner;
                        margin-right: 8px;
                        border-radius: 50%;
                        border-width: 1.5px;
                        border-style: solid;
                        border-color: $msv-order-history-loading-icon-border-color;
                        animation: spin 1s steps(8) infinite;
                        animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
                        width: $msv-order-history-heading-loading-icon-dimension;
                        height: $msv-order-history-heading-loading-icon-dimension;
                        font-family: inherit;
                    }
                }
            }
        }

        .q-col-7 {
            text-align: center;
            flex-basis: 10%;
            @media (max-width: $msv-breakpoint-m) {
                flex-basis: 25%;
            }
        }
        .q-col-8 {
            flex-basis: 10%;
            text-align: center;
            button {
                padding: 8px 30px;
            }
            &__delete-icon {
                display: flex;
            }
            @media (max-width: $msv-breakpoint-m) {
                flex-basis: 15%;
                button {
                    padding: 8px 12px;
                }
            }
        }
    }

    .q-header,
    &-order-upload__wrapper {
        display: flex;
        background: white;
        border-radius: 5px;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
        padding: 10px;
        justify-content: space-between;
        margin: 1rem 0;

        @media (max-width: $msv-breakpoint-m) {
            padding: 10px 5px;
        }

        &__controls {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: end;
        }
        &__details {
            gap: 1rem;
            display: flex;
            flex-direction: column;
        }
        &__button {
            &--add-to-cart {
                @include primary-button-light();
                width: max-content;
            }
            &--clear-form {
                @include primary-button-light();
                background: $gardner-red-1;
                width: max-content;
                &:hover {
                    background: darken($gardner-red-1, 10%);
                }
            }
        }
        &__dropdown {
            @include form-dropdown-decoration();
            padding: 10px;
        }
        .dropzone {
            margin-bottom: 8px;
            &-footer {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .eve-quick-order-order-upload__guide-link {
        text-align: center;
        a {
            font-family: 'Rubik-Regular';
            font-weight: 400;
            font-size: 14px;
            align-items: center;
            border-radius: 100px;
            letter-spacing: 0.1em;
            outline: none;
            overflow: hidden;
            text-transform: uppercase;
            background: var(--msv-primary-btn-light-bg-color);
            color: var(--msv-font-secondary-color);
            padding: 7px 30px;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        &-order-upload {
            &__wrapper {
                flex-direction: column;
                &--dropzone {
                    margin-top: 1rem !important;
                }
            }
        }
    }
}
